import React, { useEffect, useState, useCallback } from 'react';
import Stack, {
  onEntryChange,
} from '@shared/contentstack/live-preview/live-preview';
import { Header } from '@src/cms-components';
import { MainNav } from '@cms-components/header/components/main-nav/main-nav';
import { headerMapper } from '@cms-components/header/mappers/header.mapper';
import { HeaderLPContainer } from '@shared/contentstack/live-preview/live-preview-style-overrides.styles';

const HeaderPreviewPage = (props: any) => {
  const { pageContext } = props;

  const [livePreviewData, setLivePreviewData] = useState<any>(null);

  Stack.startLivePreview(true);

  const fetchData = useCallback(async () => {
    const response = (await Stack.getEntry({
      contentTypeUid: 'Header',
      url:
        pageContext?.url && pageContext?.url !== '/'
          ? pageContext?.url
          : '/live-preview',
    })) as any;

    const result = response[0][0];
    setLivePreviewData(result);
  }, [pageContext]);

  useEffect(() => {
    onEntryChange(() => fetchData());
  }, [fetchData]);

  return !!livePreviewData ? (
    <HeaderLPContainer>
      <MainNav data={headerMapper(livePreviewData)} />
    </HeaderLPContainer>
  ) : (
    <></>
  );
};

export default HeaderPreviewPage;
